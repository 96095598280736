.contact__container {
  grid-template-columns: repeat(2, max-content);
  justify-content: center;
  column-gap: 6rem;
  padding-bottom: 3rem;
  background-color: hsla(22, 62%, 76%, 1);
  border-radius: 24px;
  column-gap: 6rem;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  padding: 1rem 0;
  width: 80%;
 }
 

 .contact__container:hover {
  border-radius: 26px;
  opacity: 0.9;
  transition: all .5s ease;
  box-shadow: 0px 50px 100px -20px rgba(0, 0, 0, .15);
 }

 .contact__container:hover .contact__card{
  transform: translateY(5px);
  transition: all 1.5s ease;
 }

 .contact__container:hover .section__title{
  transform: translateY(5px);
  transition: all 1.2s ease;
 }
  
 .container__title {
  text-align: center;
  font-size: var(--h3-font-size);
  font-weight: var(--fot-medium);
  margin-bottom: var(--mb-1-5);
  margin-top: 20px;
 }
  
 .container__info {
  display: grid;
  row-gap: 1rem;
  grid-template-columns: 300px;
 }
  
 .contact__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 0.75rem;
  text-align: center;
  margin-bottom: 10px;
 }
  
 .contact__card-icon {
  font-size: 2rem;
  color: var(--title-color);
  margin-bottom: var(--mb-0-25);
 }
  
 .contact__card-title,
  
 .contact__card-data {
  font-size: var(--small-font-size);
  max-width: 360px;
  text-decoration: none;
  display: block;
  margin-bottom: var(--mb-0-75);
  color: var(--text-color);
 }
  
 .contact__card-title {
  font-weight: var(--font-medoim);
 }
  
 .contact__button {
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.25rem;
 }
  
 .contact__button-icon {
  font-size: 1rem;
  transition: 1s ease;
 }
  
 .contact__container:hover .contact__button-icon {
  transform: translate(0.25rem);
 }
  

 /*=============== BREAKPOINTS ===============*/
 /* For large devices */
 @media screen and (max-width: 992px) {

  .contact{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact__container {
    column-gap: 3rem;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    width: 80%;
  }
  .contact__card {
    margin-bottom: 0px;
   }
   .skills__container {
    grid-template-columns: repeat(2, 300px);
   }
 }
  
 /* For medium devices */
 @media screen and (max-width: 768px) {

  
  .contact__info {
    justify-content: center;
  }
  
  
  .contact__form {
    margin: 0 auto;
  }
  
  .contact__content {
    display: flex;
    justify-content: space-between;
  }

  .skills__container {
    grid-template-columns: repeat(1, 60%);
   }
 }
  
 @media screen and (max-width: 576px) {

  .contact__container {
    column-gap: 0.5rem;
    padding: 1rem;
    width: 90%;
  }
  .contact__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .contact__info {
    grid-template-columns: 1fr;
  
  }
  .contact__form {
    width: 100%;
  }

  .skills__container {
    grid-template-columns: repeat(1, 80%);
   }

 }
  
 /* For small devices */
 @media screen and (max-width: 350px) {
  .contact__container {
    row-gap: 0.5rem;
    grid-template-columns: 1fr;
    padding-bottom: 1rem;
    column-gap: 0.5rem;

  }
  .contact__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .skills__container {
    grid-template-columns: repeat(1, 90%);
   }
  
 }
 